export default [
  {
    title: 'Pure rendering in the light of time and state',
    subtitle: "(it's the assignment-operator's fault)",
    url:
      'https://medium.com/@mweststrate/pure-rendering-in-the-light-of-time-and-state-4b537d8d40b1?source=user_profile---------24------------------',
    date: '13-07-2015',
  },
  {
    title: 'MobX, ReactJS and Flux',
    subtitle: 'Interview by SurviveJS',
    url: 'http://survivejs.com/blog/mobservable-interview/',
    date: '19-08-2015',
  },
  {
    title:
      'Making React reactive: the pursuit of high performing, easily maintainable React apps',
    subtitle: '',
    url:
      'https://www.mendix.com/blog/making-react-reactive-pursuit-high-performing-easily-maintainable-react-apps/',
    date: '22-06-2015',
    star: true
  },
  {
    title: 'Distributing state changes using snapshots, patches and actions',
    subtitle: 'Part 2: Distributing patches and rebasing actions using Immer',
    url:
      'https://medium.com/@mweststrate/distributing-state-changes-using-snapshots-patches-and-actions-part-2-2f50d8363988',
    date: '20-09-2018',
  },
  {
    title: 'Distributing state changes using snapshots, patches and actions ',
    subtitle:
      'Part 1: Introducing the concepts of snapshots, patches and actions',
    url:
      'https://medium.com/@mweststrate/distributing-state-changes-using-snapshots-patches-and-actions-part-1-2811a2fcd65f',
    date: '06-09-2018',
  },
  {
    title:
      'How to fix nasty circular dependency issues once and for all in JavaScript & TypeScript',
    subtitle: 'Getting a grip on module loading order beyond trial and error',
    url:
      'https://medium.com/visual-development/how-to-fix-nasty-circular-dependency-issues-once-and-for-all-in-javascript-typescript-a04c987cf0de',
    date: '19-07-2018',
    star: true

  },
  {
    title: 'MobX 5: the saga continues…',
    subtitle: '',
    url:
      'https://medium.com/@mweststrate/mobx-5-the-saga-continues-4852bce05572',
    date: '07-06-2018',
  },
  {
    title: 'MobX 4: Better, simpler, faster, smaller',
    subtitle: '..and still, MobX 5 will be even more awesome 😺',
    url:
      'https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da',
    date: '12-03-2018',
  },
  {
    title: 'Introducing Immer: Immutability the easy way',
    subtitle: '',
    url:
      'https://hackernoon.com/introducing-immer-immutability-the-easy-way-9d73d8f71cb3',
    date: '10-01-2018',
    star: true

  },
  {
    title: 'The Curious Case of Mobx State Tree',
    subtitle: '',
    url: 'https://codeburst.io/the-curious-case-of-mobx-state-tree-7b4e22d461f',
    date: '28-09-2017',
    star: true
  },
  {
    title: 'The fundamental principles behind MobX',
    subtitle: '',
    url:
      'https://hackernoon.com/the-fundamental-principles-behind-mobx-7a725f71f3e8',
    date: '13-01-2017',
    star: true
  },
  {
    title: 'MobX 3 released: Unpeeling the onion',
    subtitle: '',
    url:
      'https://medium.com/@mweststrate/mobx-3-released-unpeeling-the-onion-ca877382f443',
    date: '09-01-2017',
  },
  {
    title: 'How to safely use React context',
    subtitle: '',
    url:
      'https://medium.com/@mweststrate/how-to-safely-use-react-context-b7e343eff076',
    date: '23-09-2016',
  },
  {
    title: 'How to decouple state and UI',
    subtitle: 'a.k.a. you don’t need componentWillMount',
    url:
      'https://hackernoon.com/how-to-decouple-state-and-ui-a-k-a-you-dont-need-componentwillmount-cc90b787aa37',
    date: '22-08-2016',
    star: true
  },
  {
    title: 'mobx-utils: community driven utility belt for MobX',
    subtitle: '',
    url:
      'https://medium.com/@mweststrate/mobx-utils-community-driven-utility-belt-for-mobx-264346cb2744',
    date: '15-08-2016',
  },
  {
    title:
      'Introducing serializr: serializing and deserializing object graphs with ease',
    subtitle: '',
    url:
      'https://medium.com/@mweststrate/introducing-serializr-serializing-and-deserializing-object-graphs-with-ease-8833c3fcea02',
    date: '11-08-2016',
  },
  {
    title: '3 Reasons why I stopped using React.setState',
    subtitle: '',
    url:
      'https://blog.cloudboost.io/3-reasons-why-i-stopped-using-react-setstate-ab73fc67a42e',
    date: '15-06-2016',
  },
  {
    title: 'MobX 2.2: explicit actions, controlled mutations and improved DX',
    subtitle: '',
    url:
      'https://medium.com/@mweststrate/mobx-2-2-explicit-actions-controlled-mutations-and-improved-dx-45cdc73c7c8d',
    date: '25-05-2016',
  },
  {
    title: 'Becoming fully reactive: an in-depth explanation of MobX',
    subtitle: '',
    url:
      'https://hackernoon.com/becoming-fully-reactive-an-in-depth-explanation-of-mobservable-55995262a254',
    date: '28-12-2015',
    star: true
  },
  {
    title: 'How to create strongly-typed npm packages',
    subtitle: '',
    url:
      'https://medium.com/@mweststrate/how-to-create-strongly-typed-npm-modules-1e1bda23a7f4',
    date: '01-12-2015',
  },
  {
    title: 'Object.observe is dead. Long live MobX.observe',
    subtitle: '',
    url:
      'https://medium.com/@mweststrate/object-observe-is-dead-long-live-mobservable-observe-ad96930140c5',
    date: '11-11-2015',
  },
  {
    title: 'REST Part II: Publishing Microflows with REST' ,
    subtitle: '',
    url: 'https://www.mendix.com/blog/rest-part-ii-publishing-microflows-rest/' ,
    date: '13-06-2014'
  },
  {
    title: 'REST Part I: Consuming Your First REST Service' ,
    subtitle: '',
    url: 'https://www.mendix.com/blog/consuming-first-rest-service/' ,
    date: '02-05-2014'
  },
  {
    title: 'Easy XPath retrieval in Java' ,
    subtitle: '',
    url: 'https://www.mendix.com/blog/easy-xpath-retrieval-in-java/' ,
    date: '13-07-2012'
  },
  {
    title: 'The Sprintr Like Button; Tales of a 16 fold performance gain.' ,
    subtitle: '',
    url: 'https://www.mendix.com/blog/the-sprintr-like-button-tales-of-a-16-fold-performance-gain/' ,
    date: '30-03-2012'
  },{
    title: 'UI as an afterthought',
    subtitle: 'Or: how will React context and hooks change the game of state management?',
    url: '/blogs/ui-as-an-afterthought',
    date: '05-02-2019'
  },{
    title: 'Announcing MobX 6',
    url: '/blogs/mobx6',
    date: '24-09-2020'
  },
  {
    title: 'MobX now supports standard decorators! (And what else is next?)',
    url: '/blogs/mobx-decorators',
    date: '14-11-2022'
  }
]
