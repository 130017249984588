import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import blogs from '../resources/blogs'
import './talks.scss'

const sortableDate = str =>
  str
    .split('-')
    .reverse()
    .join('-')

blogs
  .sort((a, b) => sortableDate(a.date).localeCompare(sortableDate(b.date)))
  .reverse()

const BlogPage = () => (
  <Layout>
    <SEO title="Blogs" />
    <center>
      <h1>Blogs</h1>
      <p>
        ...or just follow me on{' '}
        <a href="https://medium.com/@mweststrate">medium.com/@mweststrate</a>
      </p>
    </center>
    {blogs.map(blog => (
      <Blog blog={blog} key={blog.date} />
    ))}
  </Layout>
)

const Blog = ({ blog }) => (
  <div class="talk">
    <h2>
      <a href={blog.url} target="_blank" rel="noopener noreferrer">
        {blog.star ? '⭐ ' : ''}
        {blog.title}
        {blog.star ? ' ⭐' : ''}
      </a>
    </h2>
    <hr />
    {blog.subtitle ? blog.subtitle + ' | ' : ''}
    <a href={blog.url} target="_blank" rel="noopener noreferrer">
      link
    </a>
    {' | '}
    {blog.date}
  </div>
)

export default BlogPage
